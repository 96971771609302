import React from 'react';
import { DownloadIcon } from '@fluentui/react-icons-northstar'
import './Carousel.css';

class Carousel extends React.Component {
  render() {
    return (
      <div className="fullscreen row">
        <div id="page_viewer">
          <div id="prev_page" className="_disabled">
            <span className="icon"><img src="img/chevron-left.png" /></span>
          </div>
          <div id="next_page">
            <span className="icon"><img src="img/chevron-right.png" /></span>
          </div>
          <div className="page">
            <img src="" id="current_page_image" />
            <div id="current_pdf_file">
              <DownloadIcon size="small" />
              <a download href="" className="pdf_file__link"></a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Carousel;