export const TeamsThemes = {
    contrast: "constrast",
    dark: "dark",
    default: "default"
};

export const Auth = {
    appId: process.env.REACT_APP_APP_ID!,
    tenantId: process.env.REACT_APP_TENANT_ID!,
    cacheLocation: 'localStorage',
    loginStart: 'auth/login-start',
    loginEnd: 'auth/login-end',
    authenticatedDomains: {
        "https://graph.microsoft.com": "https://graph.microsoft.com"
    }
};