import React from 'react';

class PageHeader extends React.Component {
    render() {
        return (
            <>
                <input type="checkbox" className="openSidebarMenu right" id="openSidebarMenuRight" />
                <label htmlFor="openSidebarMenuRight" className="sidebarIconToggle right">
                    <div id="appnav">Apps</div>
                    <div className="spinner diagonal part-1" />
                    <div className="spinner horizontal" />
                    <div className="spinner diagonal part-2" />
                </label>
            </>
        )
    }
}

export default PageHeader;