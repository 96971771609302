import React from 'react';
import { Home } from '../pages';
import { Login, LoginStart, LoginEnd } from '../pages/auth';
import { RouteDefinition } from '../models/route-definition';

const routes: RouteDefinition[] = [
    {
        route: '/auth/login',
        component: <Login />,
        authenticated: false
    },
    {
        route: '/auth/login-start',
        component: <LoginStart />,
        authenticated: false
    },
    {
        route: '/auth/login-end',
        component: <LoginEnd />,
        authenticated: false
    },
    {
        route: '/',
        component: <Home />,
        authenticated: true
    }
];

export default routes;