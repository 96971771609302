import React from 'react';
import { Carousel, LeftMenu, PageHeader, RightMenu } from '../components/shared';

class Home extends React.Component {
  render() {
    return (
      <div>
        <PageHeader />
        <RightMenu />
        <LeftMenu />
        <Carousel />
      </div>
    )
  }
}


export default Home;