import React from 'react';

class RightMenu extends React.Component {
  render() {
    return (
      <div id="sidebarMenu" className="right">
        <ul id="apps" className="sidebarMenuInner">
          <li className="col group title">Deutsch</li>
          <li className="col teams"><a title="Teams" href="#teams" data-id="teams" id="teamsDownload"><img src="img/teams-icon.png" /><span>Teams</span></a></li>
          <li className="col onedrive"><a title="OneDrive" href="#onedrive" data-id="onedrive" id="onedriveDownload"><img src="img/onedrive-icon.png" /><span>OneDrive</span></a></li>
          <li className="col onenote"><a title="OneNote" href="#onenote" data-id="onenote" id="onenoteDownload"><img src="img/onenote-icon.png" /><span>OneNote</span></a></li>
          <li className="col outlook"><a title="Outlook" href="#outlook" data-id="outlook" id="outlookDownload"><img src="img/outlook-icon.png" /><span>Outlook</span></a></li>
          <li className="col planner"><a title="Planner" href="#planner" data-id="planner" id="plannerDownload"><img src="img/planner-icon.png" /><span>Planner</span></a></li>
          <li className="col todo"><a title="To Do" href="#todo" data-id="todo" id="todoDownload"><img src="img/todo-icon.png" alt="done" /><span>To Do</span></a></li>
          <li className="col stream"><a title="Stream" href="#stream" data-id="stream" id="streamDownload"><img src="img/stream-icon.png" /><span>Stream</span></a></li>
          <li className="col forms"><a title="Forms" href="#forms" data-id="forms" id="formsDownload"><img src="img/forms-icon.png" /><span>Forms</span></a></li>
          <li className="col powerapps"><a title="Power Apps" href="#powerapps" data-id="powerapps" id="powerappsDownload"><img src="img/powerapps-icon.png" /><span>Power Apps</span></a></li>
          <li className="col sharepoint"><a title="SharePoint" href="#sharepoint" data-id="sharepoint" id="sharepointDownload"><img src="img/sharepoint-icon.png" /><span>SharePoint</span></a></li>
          <li className="col group title">English</li>
          <li className="col teams_en"><a title="Teams english" href="#teams_en" data-id="teams_en"><img src="img/teams-icon.png" /><span>Teams</span></a></li>
          <li className="col onenote_en"><a title="OneNote english" href="#onenote_en" data-id="onenote_en"><img src="img/onenote-icon.png" /><span>OneNote</span></a></li>
          <li className="col onedrive_en"><a title="OneDrive english" href="#onedrive_en" data-id="onedrive_en"><img src="img/onedrive-icon.png" /><span>OneDrive</span></a></li>
          <li className="col sharepoint_en"><a title="SharePoint english" href="#sharepoint_en" data-id="sharepoint_en"><img src="img/sharepoint-icon.png" /><span>SharePoint</span></a></li>
        </ul>
      </div>
    )
  }
}


export default RightMenu;