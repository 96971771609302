import React from 'react';

class LeftMenu extends React.Component {
    render() {
        return (
            <>
                <input type="checkbox" className="openSidebarMenu left" id="openSidebarMenuLeft" />
                <label htmlFor="openSidebarMenuLeft" className="sidebarIconToggle left">
                    <div className="spinner diagonal part-1" />
                    <div className="spinner horizontal" />
                    <div className="spinner diagonal part-2" />
                    <div id="pagenav">Seiten</div>
                </label>
                <div id="sidebarMenu" className="left">
                    <ul className="sidebarMenuInner"></ul>
                </div>
            </>
        )
    }
}

export default LeftMenu;